export default [
  {
    id: 1,
    model: '01-panrallado.fbx',
    name: 'Pan rallado',
    nameEn: 'Pan rallado',
    template: '01-panrallado.png',
    download: '01-panrallado.png',
    objectName: '01_obj_pan_1',
    planeName: '01_plane_pan_1',
    separation: 4,
    cameraData: [0, 0.6, 0.2, 1.773 * 1.5, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 2,
    model: '02-fideos.fbx',
    name: 'Fideos',
    nameEn: 'Fideos',
    template: '02-fideos.png',
    download: '02-fideos.png',
    objectName: '01_obj_fideos',
    planeName: '01_plane_fideos',
    separation: 3,
    cameraData: [0, 0.6, 0, 1.773 * 1.5, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 3,
    model: '03-falafel.fbx',
    name: 'Falafel',
    nameEn: 'Falafel',
    template: '03-falafel.png',
    download: '03-falafel.png',
    objectName: '01_obj_falafel_1',
    planeName: '01_plane_falafel_1',
    separation: 1.5,
    cameraData: [0, 0.5, 0, 1.773 * 1.5, 1.2 * 1.5, 1.99 * 1.5],
  },
];
