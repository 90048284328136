export default [
  {
    id: 1,
    name: 'Susana, Romina, Edwin',
  },
  {
    id: 2,
    name: 'Leonel, Natalia',
  },
];
