export default [
  {
    id: 17,
    model: '2021-01-magdalenas.fbx',
    name: '2021 - Nivel 1 - Magdalenas Gaona',
    template: '2021-01-magdalenas.png',
    download: '2021-01-magdalenas.png',
    objectName: '01_obj_magdalenas_1',
    planeName: '01_plane_magdalenas_1',
    separation: 2,
    cameraData: [0, 0.7, 0, 1.773, 2, 4],
  },
  {
    id: 18,
    model: '2021-02-bocaditos.fbx',
    name: '2021 - Nivel 1 - Bocaditos Granix',
    template: '2021-02-bocaditos.png',
    download: '2021-02-bocaditos.png',
    objectName: '02_obj_bocaditos',
    planeName: '02_plane_bocaditos',
    separation: 2.2,
    cameraData: [0, 0.8, 0, 1.773, 3, 4],
  },
  {
    id: 19,
    model: '2021-03-papas.fbx',
    name: '2021 - Nivel 1 - Papas McCain',
    template: '2021-03-papas.png',
    download: '2021-03-papas.png',
    objectName: '03_obj_papas',
    planeName: '03_plane_papas',
    separation: 2.4,
    cameraData: [0, 0.8, 0, 2.1276, 3, 4],
  },
  {
    id: 1,
    model: '01-pizza.fbx',
    name: '2021 - Nivel 3 - Pizza',
    template: '01-pizza.png',
    download: '01-pizza.png',
    objectName: 'pizza_congelada_1',
    planeName: 'Plane_pizza_congelada_1',
    separation: 4,
    cameraData: [0, 0, 0, 0, 6, 6],
  },
  {
    id: 2,
    model: '02-cacao.fbx',
    name: '2021 - Nivel 3 - Cacao',
    template: '02-cacao.png',
    download: '02-cacao.png',
    objectName: '02_obj_cacao',
    planeName: '02_plane_cacao',
    separation: 1.8,
    cameraData: [0, 0.9, 0, 1.773, 1, 3],
  },
  {
    id: 3,
    model: '03-yogur.fbx',
    name: '2021 - Nivel 3 - Yogur',
    template: '03-yogur.png',
    download: '03-yogur.png',
    objectName: '03_obj_yougur',
    planeName: '03_plane_yogur',
    separation: 2,
    cameraData: [0, 1, 0, 2.1276, 3, 4],
  },
  {
    id: 4,
    model: '04-chocolate.fbx',
    name: '2021 - Nivel 3 - Chocolate',
    template: '04-chocolate.png',
    download: '04-chocolate.png',
    objectName: '04_obj_chocolate',
    planeName: '04_plane_chocolate',
    separation: 2.5,
    cameraData: [0, 0.7, 0, 2.1276, 2.4, 4.577],
  },
  {
    id: 5,
    model: '05-te.fbx',
    name: '2021 - Nivel 3 - Té',
    template: '05-te.png',
    download: '05-te.png',
    objectName: '05_obj_te',
    planeName: '05_plane_te_1',
    separation: 2.5,
    cameraData: [0, 0.7, 0, 1.48932, 1.68, 3.2039],
  },
  {
    id: 6,
    model: '06-snack.fbx',
    name: '2021 - Nivel 3 - Snack',
    template: '06-snack.png',
    download: '06-snack.png',
    objectName: '06_obj_snack',
    planeName: '06_plane_snack',
    separation: 2.5,
    cameraData: [0, 0.7, 0, 2.1276, 2.4, 4.577],
  },
  {
    id: 7,
    model: 'envase_spaghetti.fbx',
    name: '2020 - Nivel 1 - Spaghetti',
    template: 'envase_spaguetthi_horizontal.png',
    download: 'envase_spaguetthi_horizontal.png',
    objectName: 'spaghetti_1',
    planeName: 'Plane_1',
    separation: 4,
    cameraData: [0, 0.2, 0, 3, 1.2, 3],
  },
  {
    id: 8,
    model: 'envase_spaghetti_vertical.fbx',
    name: '2020 - Nivel 1 - Spaghetti Vertical',
    template: 'envase_spaguetthi_vertical.png',
    download: 'envase_spaguetthi_vertical.png',
    objectName: 'spaghetti_1',
    planeName: 'Plane_1',
    separation: 1.8,
    cameraData: [0, 1.3, 0, 1.773, 2, 6],
  },
  {
    id: 9,
    model: 'envase_pizza.fbx',
    name: '2020 - Nivel 1 - Pizza',
    template: 'envase_pizza.png',
    download: 'envase_pizza.png',
    objectName: 'pizza_1',
    planeName: 'Plane_1',
    separation: 2,
    cameraData: [0, 1, 0, 2.6595, 2.5, 4.975],
  },
  {
    id: 10,
    model: 'envase_tostadas.fbx',
    name: '2020 - Nivel 1 - Tostadas',
    template: 'envase_tostadas.png',
    download: 'envase_tostadas.png',
    objectName: 'tostadas_1',
    planeName: 'Plane_1',
    separation: 2.5,
    cameraData: [0, 0.7, 0, 2.1276, 2.4, 4.577],
  },
  {
    id: 11,
    model: 'envase_bizcochuelo.fbx',
    name: '2020 - Nivel 3 - Premezcla para bizcochuelo',
    template: 'envase_bizcochuelo.png',
    download: 'envase_bizcochuelo.png',
    objectName: 'Cube_2',
    planeName: 'Plane_1',
    separation: 2,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 12,
    model: 'envase_granola.fbx',
    name: '2020 - Nivel 3 - Granola',
    template: 'envase_granola.png',
    download: 'envase_granola.png',
    objectName: 'Granola',
    planeName: 'PlaneGranola_1',
    separation: 2.5,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 13,
    model: 'envase_leche-saborizada.fbx',
    name: '2020 - Nivel 3 - Leche Saborizada',
    template: 'envase_leche-saborizada.png',
    download: 'envase_leche-saborizada.psd',
    objectName: 'Estuche_leche_1',
    planeName: 'Plane_leche',
    separation: 1.5,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 14,
    model: 'envase_pasta-seca.fbx',
    name: '2020 - Nivel 3 - Pasta Seca',
    template: 'envase_pasta-seca.png',
    download: 'envase_pasta-seca.png',
    objectName: 'PastaSeca_1',
    planeName: 'PlanePastaSeca_1',
    separation: 2,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 15,
    model: 'envase_galletitas.fbx',
    name: '2020 - Nivel 3 - Galletitas dulces secas',
    template: 'envase_galletitas.png',
    download: 'envase_galletitas.png',
    objectName: 'Galletitas_1',
    planeName: 'PlaneGalletitas_1',
    separation: 3,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
  {
    id: 16,
    model: 'envase_estuche-vino.fbx',
    name: '2020 - Nivel 3 - Estuche para vino',
    template: 'envase_estuche-vino.png',
    download: 'envase_estuche-vino.png',
    objectName: 'Estuche_vino_1',
    planeName: 'Plane_Estuche_vino_1',
    separation: 2.5,
    cameraData: [0, 1, 0, 1.773 * 2, 1.2 * 2, 1.99 * 2],
  },
];
